import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const DarkTheme = () => createTheme(
  {
    palette: {
      primary: {
        main: '#2B543B',
        dark: '#23432F',
        light: '#386B4C',
        contrastText: '#ffffff'
      },
      secondary: {
        main: grey[800],
        dark: grey[900],
        light: grey[700],
        contrastText: '#ffffff'
      },
      background: {
        default: '#303134',
        paper: '#202124',
      }
    }
  }
);