import { createContext, useState, useCallback, useContext } from 'react';

interface IHomeAudioContext {
  muted: boolean,
  toggleMuted: () => void
};

interface IHomeAudioProviderProps {
  children: React.ReactNode
}

const AudioContext = createContext({} as IHomeAudioContext)

export const useHomeAudioContext = () => useContext(AudioContext);


export const HomeAudioProvider: React.FC<IHomeAudioProviderProps> = ({ children }) => {
  const [muted, setMuted] = useState(true);

  const toggleMuted = useCallback(() => setMuted((oldMutedState) => oldMutedState ? false : true), []);

  return (
    <AudioContext.Provider value={{ muted, toggleMuted }}>
      {children}
    </AudioContext.Provider>
  )
}