import { AppBar, Typography, Toolbar, useTheme, useMediaQuery, Button, Icon } from '@mui/material';
import Box from '@mui/system/Box';
import React from 'react';

import { useHomeAudioContext } from '../../contexts';

const menuItems = ['A Breed', 'Produtos', 'Compre agora', 'Contato']

export const Header = () => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { muted, toggleMuted } = useHomeAudioContext();

  const drawMenuButtons = () => {
    let elements: React.ReactElement[] = [];
    menuItems.map((text, key) => elements.push(<Button color="primary" sx={{ color: 'white' }} key={key}>{text}</Button>))
    return elements;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{ backgroundColor: 'transparent' }} position="fixed">
        <Box padding={theme.spacing(2)} sx={{ display: 'flex' }} alignItems="center">
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, textShadow: '5px 5px 5px black', letterSpacing: 1 }}>
            Breed Burger
          </Typography>
          <Toolbar >
            <Box sx={{ display: isSM ? 'none' : 'flex' }}>
              {drawMenuButtons()}
            </Box>
            <Box onClick={toggleMuted} style={{ margin: 20, display: 'flex' }}>
              <Icon style={{ color: 'white' }}>{muted ? 'volume_off' : 'volume_up'}</Icon>
            </Box>
            <Box sx={{ display: isSM ? 'flex' : 'none' }}>
              <Icon>menu</Icon>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </Box>);
};