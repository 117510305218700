import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const LightTheme = () => createTheme(
  {
    palette: {
      primary: {
        main: '#2B543B',
        dark: '#13331F',
        light: '#386B4C',
        contrastText: '#ffffff'
      },
      secondary: {
        main: grey[800],
        dark: grey[900],
        light: grey[700],
        contrastText: '#ffffff'
      },
      background: {
        default: '#E5E5E5',
        paper: '#ffffff',
      },
    },

  }
);