import { Typography, useMediaQuery, useTheme } from '@mui/material';

import style from './HomeVideoBanner.module.css';
import { useHomeAudioContext } from '../../contexts';

export const HomeVideoBanner = () => {


  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const isHD = useMediaQuery(theme.breakpoints.up('lg'))

  const { muted } = useHomeAudioContext();

  const audioProps = { ...(muted && { muted: true }), ...{ autoPlay: true, loop: true } }



  return (
    <div className={`${style.videoContainer}`} style={{ backgroundImage: `url(/assets/gifs/burger${isHD ? '.gif' : '.webp'})` }}>
      <audio {...audioProps} src="/assets/audios/burger_audio.mp3"></audio>

      <div className={`${style.textModal}`}>
        <Typography sx={{ zIndex: 1, color: 'white', textShadow: '7px 3px 4px #000' }} variant={isSM ? 'h4' : 'h1'} component='div'>EM CONSTRUÇÃO ...</Typography>
      </div>
    </div >
  );
}
